import { NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { CrmTranslatePipe } from 'common-module/translate';
import { NzDrawerModule, NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
  standalone: true,
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  imports: [
    NzDrawerModule,
    NzIconModule,
    CrmTranslatePipe,
    NgTemplateOutlet,
    NgIf,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DrawerComponent {
  @Input() public body: TemplateRef<any> | null = null;
  @Input() public footer: TemplateRef<any> | null = null;

  @Input() public wrapClass = '';

  @Input() public visible = false;
  @Input() public title!: string;
  @Input() public placement: NzDrawerPlacement = 'right';

  @Output() public close$ = new EventEmitter();
}
