<nz-drawer
  [nzVisible]="visible"
  [nzPlacement]="placement"
  nzWrapClassName="drawer-wrapper {{ wrapClass }}"
  nzWidth="auto"
  (nzOnClose)="close$.emit()"
>
  <ng-container *nzDrawerContent>
    <div class="drawer--header" *ngIf="title">
      <div class="drawer--title">{{ title | crmTranslate }}</div>
      <span class="clickable" (click)="close$.emit()"
        ><i nz-icon nzType="close" nzTheme="outline"></i
      ></span>
    </div>
    <ng-container *ngIf="visible">
      <div class="drawer--body">
        <ng-container *ngIf="body" [ngTemplateOutlet]="body"></ng-container>
      </div>
      <div class="drawer--footer">
        <ng-container *ngIf="footer" [ngTemplateOutlet]="footer"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</nz-drawer>
